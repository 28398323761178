<template>
  <div id="delete-modal">
    <div id="close-and-message">
      <div @click="closeModal" id="close-modal">
        <font-awesome-icon id="close-icon" icon="times" />
      </div>
      <h4 id="are-you-sure">
        {{ labels.payment.deleteCard.areYouSure[language] }}
        <span
          id="card-number"
        >{{ "****0453" || card.label }}</span>?
      </h4>
    </div>
    <div id="buttons">
      <Button
        @click="closeModal"
        :disabled="loading"
        id="not-delete-button"
      >{{ labels.payment.deleteCard.notSure[language] }}</Button>
      <Button
        @click="deleteCard"
        :loading="loading"
        :isWhiteButton="true"
        :disabled="success || loading"
        id="delete-button"
      >{{ labels.payment.deleteCard.sure[language] }}</Button>
      <h6 v-if="success" id="success-message">{{ labels.payment.deleteCard.success[language] }}</h6>
      <h6
        v-else-if="success == false"
        id="failure-message"
      >{{ labels.payment.deleteCard.failure[language] }}</h6>
    </div>
  </div>
</template>

<script>
import Button from "./Button";

import UserApi from "../api/user";
export default {
  name: "DeleteCardModal",
  components: {
    Button,
  },
  computed: {
    card() {
      return this.$store.getters.getCreditCardToDelete;
    },

    language() {
      return this.$store.getters.getLanguage
    }
  },
  data() {
    return {
      success: undefined,
      loading: false,
    };
  },
  methods: {
    closeModal: function () {
      this.$router.push({ name: "cart" });
      this.$store.dispatch("closeDeleteCreditCardModal");
      this.success = undefined;
    },

    deleteCard: async function () {
      this.loading = true;

      let result = await UserApi.deleteCreditCard(this.card.cardToken);
      if (result.status == 200) {
        // id 0 is the new card
        this.$store.dispatch("selectCard", 0);
        this.$store.dispatch("deleteCard", this.card);
        this.success = true;
      } else {
        this.success = false;
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped>
#delete-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 16px;
  width: 33vw;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  z-index: 202;
}

#close-and-message {
  display: flex;
  flex-direction: column;
}

#close-modal {
  align-self: flex-end;
  margin-right: 20px;
}

#close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

#are-you-sure {
  width: 80%;
  margin-left: 10%;
  text-align: center;
}

#buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#not-delete-button {
  margin-bottom: 20px;
  background: linear-gradient(62deg, #198b4a, #04da6f);
  color: white;
}

#delete-button {
  background: white;
  border: 1px solid #f30;
  color: #f30;
}

#delete-button,
#not-delete-button {
  width: 90%;
  font-weight: 600;
  font-size: 14px;
  box-shadow: 2px 2px 4px rgb(0, 0, 0, 0.24), rgb(0, 0, 0, 0.36) !important;
}

#card-number {
  color: #ed5005;
}

#success-message,
#failure-message {
  width: 90%;
  margin-left: 5%;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  margin-top: 20px;
}

#success-message {
  color: #198b4a;
}

#failure-message {
  color: #f30;
}

@media screen and (max-width: 800px) {
  #delete-modal {
    height: 50vh;
    width: 90vw;
  }

  #are-you-sure {
    margin-bottom: 10%;
  }
}

@media screen and (max-width: 330px) {
  #delete-modal {
    height: 60vh;
  }

  #delete-button,
  #not-delete-button {
    font-size: 12px;
  }

  #success-message,
  #failure-message {
    font-size: 12px;
  }
}
</style>