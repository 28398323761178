<template>
  <div id="choose-delivery-time-container">
    <div id="delivery-time-internal-container">
      <h5>{{labels.cart.whenWillYouReturn[language]}}</h5>
      <date-picker
        id="date-time-picker"
        @change="onDatetimeChange"
        @pick="onDatetimePick"
        @close="transformDateTimeInputValueToTimeSelectionRange(10, 65)"
        @open="transformDateTimeInputValueToTimeSelectionRange(10, 65)"
        type="datetime"
        v-model="deliveryDateTime"
        :time-picker-options="timePickerOptions"
        :disabled-date="disabledBeforeTodayAndAfterAWeek"
        :disabled-time="disabledTime"
        time-title-format="DD MMM YYYY"
        format="ddd DD MMM | HH:mm"
        placeholder="Select date and time"
        :show-second="false"
      ></date-picker>
      <h6
        id="only-scheduled-info"
        :class="{ bolder: failedToChooseEndingDateTime }"
        v-if="isOpenOnlyForScheduledOrders && !isServiceWithDelayedResponse"
      >{{labels.cart.onlyScheduledOrdersRightNow[language]}}</h6>
      <h6
        v-if="failedToChooseEndingDateTime"
        id="error-message"
      >*{{labels.cart.returnDateIsMandatory[language]}}</h6>
      <h6
        v-if="endingDateIsBeforePickUpDate"
        id="error-message"
      >*{{labels.cart.returnDateMustBeAfterPickupDate[language]}}</h6>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
  name: "ChooseEndingDate",
  props: ["serviceStartTime", "serviceEndTime", "isScheduledTimeSelectionRanged", "currentGreekDate", "isOpenOnlyForScheduledOrders", "failedToChooseEndingDateTime", "deliveryType", "restaurantNames", "firstScheduledOrdersDayDistanceFromToday", "scheduledOrdersDaysRange", "scheduledOrdersTimeSlotsDistanceInMinutes", "isServiceWithDelayedResponse", "endingDateIsBeforePickUpDate"],
  components: {
    DatePicker
  },
  data() {
    return {
      deliveryDateTime: null,
      hasEditedTimes: false
    }
  },
  watch: {
    timePickerOptions() {
      this.transformTimeSelectionOptionsToRanges(12);
    },
  },
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    },

    timePickerOptions() {
      if (this.deliveryDateTime == null) {
        return {
          start: '00:00',
          step: `00:${this.scheduledOrdersTimeSlotsDistanceInMinutes}`,
          end: '23:59',
          format: 'HH:mm'
        };
      } else {
        let selectedDate = new Date(this.deliveryDateTime);
        let isTheSameDay = this.isTheSameDay(this.currentGreekDate, selectedDate);
        if (isTheSameDay) {
          if (this.serviceStartTime && this.serviceEndTime && this.serviceStartTime != '' && this.serviceEndTime != '') {

            let fixedServiceEndTime = this.serviceEndTime == '00:00:00' ? '23:59:59' : this.serviceEndTime;
            if (fixedServiceEndTime > this.serviceStartTime) {
              if (!this.currentTime > fixedServiceEndTime) {
                return {
                  start: this.currentTime,
                  step: `00:${this.scheduledOrdersTimeSlotsDistanceInMinutes}`,
                  end: fixedServiceEndTime,
                  format: 'HH:mm'
                }
              } else {
                return {
                  start: this.serviceStartTime,
                  step: `00:${this.scheduledOrdersTimeSlotsDistanceInMinutes}`,
                  end: fixedServiceEndTime,
                  format: 'HH:mm'
                }
              }

            } else {
              if (this.currentTime > fixedServiceEndTime && this.currentTime < this.serviceStartTime) {
                return {
                  start: this.serviceStartTime,
                  step: `00:${this.scheduledOrdersTimeSlotsDistanceInMinutes}`,
                  end: '23:59',
                  format: 'HH:mm'
                };
              } else if (this.currentTime > this.serviceStartTime && this.currentTime < '23:59:59') {
                return {
                  start: this.currentTime,
                  step: `00:${this.scheduledOrdersTimeSlotsDistanceInMinutes}`,
                  end: '23:59',
                  format: 'HH:mm'
                };
              } else if (this.currentTime > '00:00:00' && this.currentTime < fixedServiceEndTime) {
                return {
                  start: '00:00',
                  step: `00:${this.scheduledOrdersTimeSlotsDistanceInMinutes}`,
                  end: '23:59',
                  format: 'HH:mm'
                };
              } else {
                return {
                  start: '00:00',
                  step: `00:${this.scheduledOrdersTimeSlotsDistanceInMinutes}`,
                  end: '23:59',
                  format: 'HH:mm'
                };
              }
            }
          } else {
            return {
              start: '00:00',
              step: `00:${this.scheduledOrdersTimeSlotsDistanceInMinutes}`,
              end: '23:59',
              format: 'HH:mm'
            };
          }
        } else {
          if (this.serviceStartTime && this.serviceEndTime && this.serviceStartTime != '' && this.serviceEndTime != '') {
            let fixedServiceEndTime = this.serviceEndTime == '00:00:00' ? '23:59:59' : this.serviceEndTime;
            if (fixedServiceEndTime > this.serviceStartTime) {
              return {
                start: this.serviceStartTime,
                step: `00:${this.scheduledOrdersTimeSlotsDistanceInMinutes}`,
                end: fixedServiceEndTime,
                format: 'HH:mm'
              }
            } else {
              return {
                start: '00:00',
                step: `00:${this.scheduledOrdersTimeSlotsDistanceInMinutes}`,
                end: '23:59',
                format: 'HH:mm'
              };
            }
          } else {
            return {
              start: '00:00',
              step: `00:${this.scheduledOrdersTimeSlotsDistanceInMinutes}`,
              end: '23:59',
              format: 'HH:mm'
            };
          }
        }
      }

    },

    currentTime() {
      let currentHour =
        this.currentGreekDate.getHours() > 9
          ? this.currentGreekDate.getHours()
          : `0${this.currentGreekDate.getHours()}`;
      let currentMinute =
        this.currentGreekDate.getMinutes() > 9
          ? this.currentGreekDate.getMinutes()
          : `0${this.currentGreekDate.getMinutes()}`;
      let currentSecond =
        this.currentGreekDate.getSeconds() > 9
          ? this.currentGreekDate.getSeconds()
          : `0${this.currentGreekDate.getSeconds()}`;
      let currentTime = `${currentHour}:${currentMinute}:${currentSecond}`;

      return currentTime;
    }

  },
  methods: {
    onDatetimeChange() {
      this.$emit("ending-datetime-pick", this.deliveryDateTime)
      this.transformDateTimeInputValueToTimeSelectionRange(10, 65);
    },

    onDatetimePick() {
      this.transformDateTimeInputValueToTimeSelectionRange(10, 65);
      this.transformTimeSelectionOptionsToRanges(8);
    },

    transformDateTimeInputValueToTimeSelectionRange(timesToTryToFindCollection, timeout) {
      if (this.isScheduledTimeSelectionRanged) {
        setTimeout(() => {
          const collection = document.getElementsByClassName("mx-input");
          if (collection[1] && collection[1].value) {
            let transformedInputValueWithRange = this.addTimeSelectionEndingRange(collection[1].value);
            collection[1].value = transformedInputValueWithRange;
          } else if (collection[0] && collection[0].value) {
            let transformedInputValueWithRange = this.addTimeSelectionEndingRange(collection[0].value);
            collection[0].value = transformedInputValueWithRange;
          } else {
            timesToTryToFindCollection = timesToTryToFindCollection - 1;
            if (timesToTryToFindCollection >= 1) {
              this.transformDateTimeInputValueToTimeSelectionRange(timesToTryToFindCollection, timeout);
            }
          }
        }, timeout);

        setTimeout(() => {
          const collection = document.getElementsByClassName("mx-input");
          if (collection[1] && collection[1].value) {
            let transformedInputValueWithRange = this.addTimeSelectionEndingRange(collection[1].value);
            collection[1].value = transformedInputValueWithRange;
          } else if (collection[0] && collection[0].value) {
            let transformedInputValueWithRange = this.addTimeSelectionEndingRange(collection[0].value);
            collection[0].value = transformedInputValueWithRange;
          }
        }, 600);
      }
    },

    addTimeSelectionEndingRange(value) {
      let valueSplitInArray = value.split(" ");
      let selectedTime = valueSplitInArray[valueSplitInArray.length - 1];
      //if selectedTime includes "-" then it is already a range and we return the initial value
      if (!selectedTime.includes("-")) {
        let selectedTimeSplitInArray = selectedTime.split(":");
        let hour = parseInt(selectedTimeSplitInArray[0]);
        let minutes = parseInt(selectedTimeSplitInArray[1]);
        let endRangeHour = hour;
        let endRangeMinutes = minutes + this.scheduledOrdersTimeSlotsDistanceInMinutes;

        if (endRangeMinutes >= 60) {
          let amountOfExtraHours = Math.floor(endRangeMinutes / 60);
          endRangeMinutes = endRangeMinutes - 60 * amountOfExtraHours;
          endRangeHour = (endRangeHour + amountOfExtraHours == 24) ? 0 : endRangeHour + amountOfExtraHours;
        }

        let endRangeHourString = endRangeHour < 10 ? `0${endRangeHour}` : `${endRangeHour}`;
        let endRangeMinutesString = endRangeMinutes < 10 ? `0${endRangeMinutes}` : `${endRangeMinutes}`;
        let transformedValue = `${value}-${endRangeHourString}:${endRangeMinutesString}`;
        return transformedValue;
      } else {
        return value;
      }
    },

    transformTimeSelectionOptionsToRanges(timesToTryToFindCollection) {
      if (this.isScheduledTimeSelectionRanged) {
        setTimeout(() => {
          let collection = document.getElementsByClassName("mx-time-option");
          if (collection != null && collection.length > 0 && !collection[0].innerText.includes("-")) {
            for (let i = 0; i < collection.length; i++) {
              if (i < collection.length - 1) {
                let initialInnerText = collection[i].innerText;
                let innerTextOfNext = collection[i + 1].innerText;
                collection[i].innerHTML = initialInnerText + " - " + innerTextOfNext;
              } else {
                collection[i].remove();
              }
            }
          } else {
            timesToTryToFindCollection = timesToTryToFindCollection - 1;
            if (timesToTryToFindCollection >= 1) {
              this.transformTimeSelectionOptionsToRanges(timesToTryToFindCollection);
            }
          }
        }, 65);
      }
    },

    isTheSameDay(date1, date2) {
      return date1.getDate() == date2.getDate() && date1.getMonth() == date2.getMonth() && date1.getFullYear() == date2.getFullYear();
    },

    disabledTime(date) {
      if (this.deliveryDateTime != null) {
        let selectedDate = new Date(this.deliveryDateTime);
        let isTheSameDay = this.isTheSameDay(this.currentGreekDate, selectedDate);
        if (isTheSameDay) {
          if (this.serviceStartTime && this.serviceEndTime && this.serviceStartTime != '' && this.serviceEndTime != '') {
            if (this.serviceEndTime < this.serviceStartTime) {
              if (this.currentTime < this.serviceEndTime) {
                let serviceStartTimeSplit = this.serviceStartTime.split(":");
                let serviceEndTimeSplit = this.serviceEndTime.split(":");
                return (date.getHours() > (serviceEndTimeSplit[0] - 1) && date.getHours() < (serviceStartTimeSplit[0])) || date.getTime() < this.currentGreekDate.getTime();
              } else if (this.currentTime > this.serviceEndTime && this.currentTime < this.serviceStartTime) {
                let serviceStartTimeSplit = this.serviceStartTime.split(":");
                let serviceEndTimeSplit = this.serviceEndTime.split(":");
                return date.getHours() > (serviceEndTimeSplit[0] - 1) && date.getHours() < (serviceStartTimeSplit[0]);
              }
            } else {
              return date.getTime() < this.currentGreekDate.getTime()
            }
          }
        } else {
          if (this.serviceStartTime && this.serviceEndTime && this.serviceStartTime != '' && this.serviceEndTime != '') {
            if (this.serviceEndTime < this.serviceStartTime) {
              let serviceStartTimeSplit = this.serviceStartTime.split(":");
              let serviceEndTimeSplit = this.serviceEndTime.split(":");
              return date.getHours() > (serviceEndTimeSplit[0] - 1) && date.getHours() < (serviceStartTimeSplit[0]);
            }
          }
        }
      }
    },

    disabledBeforeTodayAndAfterAWeek(date) {
      let firstDateForScheduledOrders = new Date();
      if (this.firstScheduledOrdersDayDistanceFromToday > 0) {
        firstDateForScheduledOrders.setDate(firstDateForScheduledOrders.getDate() + this.firstScheduledOrdersDayDistanceFromToday);
      }
      firstDateForScheduledOrders.setHours(0, 0, 0, 0);
      return date < firstDateForScheduledOrders || date > new Date(firstDateForScheduledOrders.getTime() + (this.scheduledOrdersDaysRange - 1) * 24 * 3600 * 1000);
    },
  },
  mounted() {
  }
};
</script>

<style scoped>
#choose-delivery-time-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 1vh;
  border-bottom: 1px solid rgba(116, 135, 150, 0.2);
  padding: 7.5px 0 7.5px 0;
  color: black;
}

#only-scheduled-info {
  margin-bottom: 2px;
  margin-top: 7px;
  font-size: 13px;
  font-weight: 600;
  font-style: italic;
  color: #1a7943;
  width: 90%;
}

.bolder {
  color: #003340 !important;
  font-weight: 800 !important;
  font-style: normal !important;
}

#delivery-time-internal-container > h5 {
  font-weight: 600;
  font-size: 14px;
}

#delivery-time-internal-container {
  display: flex;
  flex-direction: column;
}

.input {
  accent-color: #198b4a;
  margin-bottom: 6px;
  margin-right: 5px;
}

#date-time-picker {
  width: 80%;
}

#department-name {
  color: #198b4a;
  font-weight: 800;
}

#error-message {
  margin-bottom: 2px;
  margin-top: 6px;
  font-size: 13px;
  font-weight: 600;
  color: #5b0101;
}

@media screen and (max-width: 360px) {
  #only-scheduled-info {
    width: 100%;
  }
}

@media screen and (max-width: 307px) {
  #date-time-picker {
    width: 100%;
  }
}
</style>