<template>
  <div :class="{ 'form-error': formError && !isFormFilled }" id="delivery-info-form">
    <div id="delivery-info-title-container">
      <h2 id="delivery-info-title">{{ labels.delivery.deliveryInfo[language] }}</h2>
    </div>
    <div id="delivery-info-container">
      <div id="address">
        <h4 id="address-label">{{ labels.delivery.address[language] }}</h4>
        <div id="address-input-container">
          <input
            type="text"
            name="address-input"
            id="address-input"
            v-model="address"
            :disabled="isDeliveryInfoFormDisabled"
          />
          <font-awesome-icon @click="clearField('address-input')" class="clear-input" icon="times" />
        </div>
      </div>
      <div id="area">
        <h4 id="area-label">{{ labels.delivery.area[language] }}</h4>
        <div id="area-input-container">
          <input
            type="text"
            name="area-input"
            id="area-input"
            v-model="area"
            :disabled="isDeliveryInfoFormDisabled"
          />
          <font-awesome-icon @click="clearField('area-input')" class="clear-input" icon="times" />
        </div>
      </div>
      <div id="postal-code-and-floor">
        <div id="postal-code">
          <h4 id="postal-code-label">{{ labels.delivery.postalCode[language] }}</h4>
          <div id="postal-code-input-container">
            <input
              type="text"
              name="postal-code-input"
              id="postal-code-input"
              v-model="postalCode"
              :disabled="isDeliveryInfoFormDisabled"
            />
            <font-awesome-icon
              @click="clearField('postal-code-input')"
              class="clear-input"
              icon="times"
            />
          </div>
        </div>
        <div id="floor">
          <h4 id="floor-label">{{ labels.delivery.floor[language] }}</h4>
          <div id="floor-input-container">
            <input
              type="text"
              name="floor-input"
              id="floor-input"
              v-model="floor"
              :disabled="isDeliveryInfoFormDisabled"
            />
            <font-awesome-icon @click="clearField('floor-input')" class="clear-input" icon="times" />
          </div>
        </div>
      </div>
      <div id="doorbell">
        <h4 id="doorbell-label">{{ labels.delivery.doorbell[language] }}</h4>
        <div id="doorbell-input-container">
          <input
            type="text"
            name="doorbell-input"
            id="doorbell-input"
            v-model="doorbell"
            :disabled="isDeliveryInfoFormDisabled"
          />
          <font-awesome-icon
            @click="clearField('doorbell-input')"
            class="clear-input"
            icon="times"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "./Button.vue";
export default {
  components: {
    Button,
  },
  computed: {
    address: {
      get() {
        return this.storedAddress;
      },

      set(newAddress) {
        this.$store.dispatch("setDeliveryAddress", { address: newAddress, shouldSave: !this.isDeliveryInfoFormDisabled });
      },
    },

    area: {
      get() {
        return this.storedArea;
      },

      set(newArea) {
        this.$store.dispatch("setDeliveryArea", { area: newArea, shouldSave: !this.isDeliveryInfoFormDisabled });
      },
    },

    postalCode: {
      get() {
        return this.storedPostalCode;
      },

      set(newPostalCode) {
        this.$store.dispatch("setDeliveryPostalCode", { postalCode: newPostalCode, shouldSave: !this.isDeliveryInfoFormDisabled });
      },
    },

    floor: {
      get() {
        return this.storedFloor;
      },

      set(newFloor) {
        this.$store.dispatch("setDeliveryFloor", { floor: newFloor, shouldSave: !this.isDeliveryInfoFormDisabled });
      },
    },

    doorbell: {
      get() {
        return this.storedDoorbell;
      },

      set(newDoorbell) {
        this.$store.dispatch("setDeliveryDoorbell", { doorbell: newDoorbell, shouldSave: !this.isDeliveryInfoFormDisabled });
      },
    },

    storedAddress() {
      return this.$store.getters.getDeliveryAddress;
    },

    storedArea() {
      return this.$store.getters.getDeliveryArea;
    },

    storedPostalCode() {
      return this.$store.getters.getDeliveryPostalCode;
    },

    storedFloor() {
      return this.$store.getters.getDeliveryFloor;
    },

    storedDoorbell() {
      return this.$store.getters.getDeliveryDoorbell;
    },

    isFormFilled() {
      return (
        this.storedAddress != undefined &&
        this.storedAddress.trim() != "" &&
        this.storedArea != undefined &&
        this.storedArea.trim() != "" &&
        this.storedPostalCode != undefined &&
        this.storedPostalCode.trim() != "" &&
        this.storedFloor != undefined &&
        this.storedFloor.trim() != "" &&
        this.storedDoorbell != undefined &&
        this.storedDoorbell.trim() != ""
      );
    },

    isDeliveryInfoFormDisabled() {
      return this.$store.getters.isDeliveryInfoFormDisabled;
    },

    language() {
      return this.$store.getters.getLanguage;
    },
  },
  data() {
    return {
      formError: false,
    };
  },
  methods: {
    clearField: function (field) {
      if (this.isDeliveryInfoFormDisabled) {
        return;
      }

      if (field == "address-input") {
        this.$store.dispatch("setDeliveryAddress", "");
      } else if (field == "area-input") {
        this.$store.dispatch("setDeliveryArea", "");
      } else if (field == "postal-code-input") {
        this.$store.dispatch("setDeliveryPostalCode", "");
      } else if (field == "floor-input") {
        this.$store.dispatch("setDeliveryFloor", "");
      } else if (field == "doorbell-input") {
        this.$store.dispatch("setDeliveryDoorbell", this.userDoorbell);
      }
    },

    triggerErrorIndicator: function () {
      this.formError = true;
    },

    formComplete: function () {
      this.formError = false
    }
  },
};
</script>

<style scoped>
#delivery-info-form {
  position: absolute;
  top: 20vh;
  right: 10px;
  width: max(20%, 300px);
  background: white;
  border-radius: 12px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36);
  z-index: 201;
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width: 1499px) {
  #delivery-info-form {
    right: 10px;
  }
}

.form-error {
  box-shadow: 2px 2px 4px rgba(255, 51, 0, 0.48),
    0px 0px 8px rgba(255, 51, 0, 0.6) !important;
}

#delivery-info-title-container {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5%;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(116, 135, 150, 0.6);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.23);
}

#delivery-info-title {
  font-weight: 600;
  font-size: calc(0.7rem + 1vw);
  font-size: 16px;
  font-weight: 700;
  color: #198b4a;
}

#delivery-info-container {
  width: 100%;
  padding: 0 5%;
}

#address,
#area,
#doorbell,
#phone {
  width: 100%;
}

#postal-code,
#floor {
  width: 45%;
}

#contact-us-form > h4,
#name > h4,
#phone > h4,
#address-label,
#area-label,
#postalc-code-label,
#floor-label,
#doorbell-label,
#phone {
  color: #333;
  font-weight: 600;
  font-size: 14px;
  align-self: flex-start;
  margin-left: 2px;
}

#address-input-container,
#area-input-container,
#postal-code-input-container,
#floor-input-container,
#doorbell-input-container,
#phone-input-container {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 6px;
  box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.2),
    inset 1px 1px 1px 0 rgba(0, 0, 0, 0.05);
  /* border: 1px solid gainsboro; */
  height: 28px;
  padding-left: 1px;
  background: #f2f2f2;
}

#area,
#postal-code-and-floor,
#doorbell {
  margin-top: 30px;
}

#doorbell {
  margin-bottom: 5%;
}

#postal-code-and-floor {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

input {
  border: none;
  border-radius: 6px;
  width: 100%;
  padding: 0 10px 0 10px;
  background: #f2f2f2;
  -webkit-appearance: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f2f2f2 inset !important;
  box-shadow: 0 0 0 30px #f2f2f2 inset !important;
}

.clear-input {
  margin: 0 10px;
  color: gray !important;
  font-size: calc(0.7rem + 0.5vw);
}

input:focus {
  outline: none;
}

textarea {
  resize: none;
  outline: none;
  border: none;
  width: 100%;
  background: white;

  justify-self: center;
  align-self: center;
  min-height: 10vh;
  max-height: 17vh;
  box-shadow: inset 1px 1px 4px 0 rgba(0, 0, 0, 0.2),
    inset -1px -1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.2),
    inset 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 18px;
  padding-left: 13px;
  padding-top: 7px;
  margin-bottom: 5%;
}
</style>