<template>
  <div id="email-input-container">
    <div id="header-and-input-row-container">
      <h5 id="email-input-title">Email:</h5>
      <input type="email" id="email-input" @input="onEmailChange" v-model="email" />
    </div>
    <h6
      v-if="failedToFillEmail"
      id="error-message"
    >*{{labels.cart.fillCorrectEmailAddress[language]}}</h6>
    <h6
      v-if="isEmailSentToUserAtOrderExecution"
      id="info-message"
    >{{ isServiceWithDelayedResponse ? labels.cart.informViaThisEmailRegardingRequest[language] : labels.cart.informViaThisEmailRegardingOrder[language]}} {{ (tableType=='QR_SELF_SERVICE' || tableType=='SELF_SERVICE_POS') && deliveryType!='RENTALS' ? labels.cart.forPickup[language] : ''}}</h6>
  </div>
</template>

<script>
export default {
  name: "CartEmailInput",
  props: ["failedToFillEmail", "isServiceWithDelayedResponse", "isEmailSentToUserAtOrderExecution", "tableType", "deliveryType"],
  components: {
  },
  data() {
    return {
      email: null
    }
  },
  watch: {

  },
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    }

  },
  methods: {
    onEmailChange() {
      this.$emit("email-input-change", this.email);
    }
  },
  mounted() {

  }
};
</script>

<style scoped>
#email-input-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 1vh;
  border-bottom: 1px solid rgba(116, 135, 150, 0.2);
  padding: 7.5px 0 7.5px 0;
  color: black;
}

#header-and-input-row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

#email-input-title {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 0px;
  margin-right: 6px;
}

#email-input {
  border: none;
  border-radius: 8px;
  font-size: 15px;
  box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.2),
    inset 1px 1px 2px 0 rgba(0, 0, 0, 0.05);
  width: 85%;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  padding-right: 10px;
  margin-right: 8px;
  background: #f7f7f7;
  -webkit-appearance: none !important;
}

#error-message {
  margin-bottom: 2px;
  margin-top: 2px;
  font-size: 13px;
  font-weight: 800;
  color: #000ecc;
}

#info-message {
  margin-bottom: 2px;
  font-size: 13px;
  font-weight: 600;
  color: #003340;
  width: 85%;
}
</style>