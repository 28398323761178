<template>
  <div id="mandatory-extras-container">
    <div id="mandatories-title-and-amount">
      <h6>{{ howManyExtras }}</h6>
      <select @change="onMandatoryExtraSelect" required v-model="selectedAmount">
        <option selected disabled :value="null">{{ labels.cart.chooseHere[language] }}</option>
        <option
          :value="0"
          v-if="mandatoryExtrasOnOrderType=='BEACH_CHAIR' || mandatoryExtrasOnOrderType=='SET_BEACH_CHAIR' "
        >{{ labels.cart.iHaveAlreadyPaidForTheBeachChairs[language] }}</option>
        <option
          :value="-1"
          v-if="mandatoryExtrasOnOrderType=='KIDS' ||  mandatoryExtrasOnOrderType=='KIDS_WITH_JUICE' "
        >{{ labels.cart.iHaveAlreadyPaidForTheKids[language] }}</option>
        <option
          :value="0"
          v-if="mandatoryExtrasOnOrderType=='KIDS' ||  mandatoryExtrasOnOrderType=='KIDS_WITH_JUICE' "
        >{{ labels.cart.iDontAccompanyKids[language] }}</option>
        <option
          v-for="n in 10"
          :key="n"
          :value="n"
        >{{n}} {{n==1 ? extrasSingularNameOption : extrasPluralNameOption }} ({{(n*priceOfMandatoryExtraItem).toFixed(2)}}€)</option>
      </select>
      <h6
        id="kids-extras-message"
        v-if="mandatoryExtrasOnOrderType=='KIDS_WITH_JUICE' && (selectedAmount==null || selectedAmount>0)"
      >*{{ labels.cart.forEveryKidYouGetOneJuice[language] }}</h6>
    </div>
  </div>
</template>

<script>
export default {
  name: "MandatoryExtraOnOrder",
  components: {},
  props: ["mandatoryExtrasOnOrderType", "priceOfMandatoryExtraItem"],
  data() {
    return {
      selectedAmount: null,
    }
  },
  watch: {

  },
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    },
    extrasSingularNameOption() {
      if (this.mandatoryExtrasOnOrderType == 'BEACH_CHAIR') {
        return this.labels.cart.singularBeachChair[this.language]
      }

      if (this.mandatoryExtrasOnOrderType == 'SET_BEACH_CHAIR') {
        return this.labels.cart.setBeachChair[this.language]
      }

      if (this.mandatoryExtrasOnOrderType == 'KIDS' || this.mandatoryExtrasOnOrderType == 'KIDS_WITH_JUICE') {
        return this.labels.cart.singularKid[this.language]
      }
    },
    extrasPluralNameOption() {
      if (this.mandatoryExtrasOnOrderType == 'BEACH_CHAIR') {
        return this.labels.cart.pluralBeachChair[this.language]
      }

      if (this.mandatoryExtrasOnOrderType == 'SET_BEACH_CHAIR') {
        return this.labels.cart.setBeachChair[this.language]
      }

      if (this.mandatoryExtrasOnOrderType == 'KIDS' || this.mandatoryExtrasOnOrderType == 'KIDS_WITH_JUICE') {
        return this.labels.cart.pluralKids[this.language]
      }
    },
    howManyExtras() {
      if (this.mandatoryExtrasOnOrderType == 'BEACH_CHAIR') {
        return this.labels.cart.howManyBeachChairs[this.language]
      }

      if (this.mandatoryExtrasOnOrderType == 'SET_BEACH_CHAIR') {
        return this.labels.cart.howManyBeachChairSets[this.language]
      }

      if (this.mandatoryExtrasOnOrderType == 'KIDS' || this.mandatoryExtrasOnOrderType == 'KIDS_WITH_JUICE') {
        return this.labels.cart.howManyKids[this.language]
      }
    },

  },
  methods: {
    onMandatoryExtraSelect() {
      this.$emit("mandatory-extra-selected", this.selectedAmount);
    }
  }
};
</script>

<style scoped>
#mandatory-extras-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 1vh;
  border-bottom: 1px solid rgba(116, 135, 150, 0.2);
  padding: 7.5px 0 7.5px 0;
  color: black;
}

#mandatories-title-and-amount {
  display: flex;
  flex-direction: column;
}

#mandatories-title-and-amount > h6 {
  font-size: 14px;
  font-weight: 600;
}

#mandatories-title-and-amount > h6 > span {
  cursor: pointer;
  color: #198b4a;
  font-weight: bold;
  margin-left: 5px;
  font-size: 14px;
}

select {
  display: flex;
  width: 95%;
  -webkit-appearance: auto !important;
  box-shadow: inset 0px 0px 2px rgba(116, 135, 150, 0.2),
    inset 1px 1px 2px rgba(116, 135, 150, 0.36);
  border-radius: 6px;
  margin-bottom: 0.5rem;
  padding: 2px;
  color: #198b4a !important;
  font-weight: bold;
  background: #f7f7f7;
}

#kids-extras-message {
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 600;
  color: #003340;
  width: 90%;
}

@media screen and (max-width: 800px) {
  select {
    font-size: 14.5px;
  }
}
@media screen and (max-width: 300px) {
  select {
    font-size: 12px;
  }
}

option {
  color: #198b4a !important;
  font-weight: 600;
}
</style>