<template>
  <div id="delivery-details">
    <div id="delivery-details-title-container">
      <p id="delivery-details-title">{{ labels.delivery.deliveryInfo[language] }}</p>
      <font-awesome-icon id="delivery-icon" icon="bicycle" />
    </div>
    <div @click="openDeliveryInfoForm" id="address-container">
      <div id="address-and-change-button">
        <div v-if="!hasDeliveryInfo" id="address-placeholder">
          <p id="placeholder-text">{{ labels.delivery.insertInfo[language] }}</p>
        </div>
        <div v-else id="name-and-address">
          <p id="address">{{ deliveryAddress }}, {{ deliveryArea }}</p>
          <p id="floor-and-name">{{ deliveryFloor }} - {{ deliveryDoorbell }}</p>
        </div>
        <font-awesome-icon
          id="change-info-icon"
          icon="chevron-right"
          :style="{ color: '#198b4a ' }"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    hasDeliveryInfo() {
      return this.deliveryAddress != undefined;
    },

    deliveryAddress() {
      return this.$store.getters.getDeliveryAddress;
    },

    deliveryArea() {
      return this.$store.getters.getDeliveryArea;
    },

    deliveryFloor() {
      return this.$store.getters.getDeliveryFloor;
    },

    deliveryDoorbell() {
      return this.$store.getters.getDeliveryDoorbell;
    },

    language() {
      return this.$store.getters.getLanguage;
    },
  },
  methods: {
    openDeliveryInfoForm: function () {
      this.$store.dispatch("deliveryInfoFormIsOpen");
    },
  },
};
</script>

<style scoped>
#delivery-details {
  position: relative;
  width: 95%;
  background: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 0 3% 0;
  margin-left: 2.5%;
  margin-top: 10vh;
}

#delivery-details-title-container {
  width: 92%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#delivery-details-title {
  margin: 0;
  margin-bottom: 15px;
  font-weight: 600;
}

#delivery-icon {
  margin-bottom: 12px;
  color: #333;
}

#address-container {
  width: 95%;
  background: #f7fff7;
  border: 1px solid gainsboro;
  padding: 5px;
  min-height: 30px;
  border-radius: 8px;
}

#address-and-change-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#address-placeholder {
  margin-left: 4px;
  margin-top: 2px;
}

#name-and-address {
  padding-right: 10px;
}

#placeholder-text {
  margin: 0;
  color: #198b4a;
  font-weight: 600;
  font-size: calc(0.3rem + 2vw);
  white-space: pre-line;
}

#address {
  margin: 0;
  margin-bottom: 5px;
  margin-top: 2px;
  margin-left: 4px;
  font-size: calc(0.3rem + 2vw);
  font-weight: 600;
}

#floor-and-name {
  margin: 0;
  margin-left: 4px;
  color: gray;
  font-weight: 500;
  font-size: calc(0.2rem + 2vw);
}

#change-info-icon {
  margin-right: 5px;
}
</style>