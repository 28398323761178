<template>
  <div :class="{ open: isDeliveryInfoFormOpen }" id="delivery-info-form">
    <div id="go-back-container">
      <font-awesome-icon
        @click="closeForm"
        id="back-to-cart-icon"
        icon="chevron-left"
        :style="{ color: '#198b4a ' }"
      />
      <h2 id="delivery-info-title">{{ labels.delivery.deliveryInfo[language] }}</h2>
    </div>
    <div id="delivery-info-container">
      <div id="address">
        <h4 id="address-label">{{ labels.delivery.address[language] }}</h4>
        <div id="address-input-container">
          <input
            type="text"
            name="address-input"
            id="address-input"
            v-model="address"
            :disabled="isDeliveryInfoFormDisabled"
          />
          <font-awesome-icon @click="clearField('address-input')" class="clear-input" icon="times" />
        </div>
      </div>
      <div id="area">
        <h4 id="area-label">{{ labels.delivery.area[language] }}</h4>
        <div id="area-input-container">
          <input
            type="text"
            name="area-input"
            id="area-input"
            v-model="area"
            :disabled="isDeliveryInfoFormDisabled"
          />

          <font-awesome-icon @click="clearField('area-input')" class="clear-input" icon="times" />
        </div>
      </div>
      <div id="postal-code-and-floor">
        <div id="postal-code">
          <h4 id="postal-code-label">{{ labels.delivery.postalCode[language] }}</h4>
          <div id="postal-code-input-container">
            <input
              type="text"
              name="postal-code-input"
              id="postal-code-input"
              v-model="postalCode"
              :disabled="isDeliveryInfoFormDisabled"
            />
            <font-awesome-icon
              @click="clearField('postal-code-input')"
              class="clear-input"
              icon="times"
            />
          </div>
        </div>
        <div id="floor">
          <h4 id="floor-label">{{ labels.delivery.floor[language] }}</h4>
          <div id="floor-input-container">
            <input
              type="text"
              name="floor-input"
              id="floor-input"
              v-model="floor"
              :disabled="isDeliveryInfoFormDisabled"
            />
            <font-awesome-icon @click="clearField('floor-input')" class="clear-input" icon="times" />
          </div>
        </div>
      </div>
      <div id="doorbell">
        <h4 id="doorbell-label">{{ labels.delivery.doorbell[language] }}</h4>
        <div id="doorbell-input-container">
          <input
            type="text"
            name="doorbell-input"
            id="doorbell-input"
            v-model="doorbell"
            :disabled="isDeliveryInfoFormDisabled"
          />
          <font-awesome-icon
            @click="clearField('doorbell-input')"
            class="clear-input"
            icon="times"
          />
        </div>
      </div>

      <div id="submit-button-container">
        <Button
          @click="submit"
          :loading="false"
          :disabled="!isFormFilled"
          id="submit-button"
        >{{ labels.delivery.proceed[language] }}</Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "./Button.vue";
export default {
  components: {
    Button,
  },
  mounted() {
    this.synchronizeFormWithStoreData();
  },
  computed: {
    address: {
      get() {
        return this.userAddress;
      },

      set(newAddress) {
        this.userAddress = newAddress;
      },
    },

    area: {
      get() {
        return this.userArea;
      },

      set(newArea) {
        this.userArea = newArea;
      },
    },

    postalCode: {
      get() {
        return this.userPostalCode;
      },

      set(newPostalCode) {
        this.userPostalCode = newPostalCode;
      },
    },

    floor: {
      get() {
        return this.userFloor;
      },

      set(newFloor) {
        this.userFloor = newFloor;
      },
    },

    doorbell: {
      get() {
        return this.userDoorbell;
      },

      set(newDoorbell) {
        this.userDoorbell = newDoorbell;
      },
    },

    isDeliveryInfoFormOpen() {
      return this.$store.getters.isDeliveryInfoFormOpen;
    },

    isDeliveryInfoFormDisabled() {
      return this.$store.getters.isDeliveryInfoFormDisabled;
    },

    isFormFilled() {
      return (
        this.userAddress != undefined &&
        this.userAddress.trim() != "" &&
        this.userArea != undefined &&
        this.userArea.trim() != "" &&
        this.userPostalCode != undefined &&
        this.userPostalCode.trim() != "" &&
        this.userFloor != undefined &&
        this.userFloor.trim() != "" &&
        this.userDoorbell != undefined &&
        this.userDoorbell.trim() != ""
      );
    },

    language() {
      return this.$store.getters.getLanguage;
    },
  },
  data() {
    return {
      userAddress: undefined,
      userArea: undefined,
      userPostalCode: undefined,
      userFloor: undefined,
      userDoorbell: undefined,
    };
  },
  methods: {
    clearField: function (field) {
      if (this.isDeliveryInfoFormDisabled) {
        return;
      }

      if (field == "address-input") {
        this.userAddress = "";
      } else if (field == "area-input") {
        this.userArea = "";
      } else if (field == "postal-code-input") {
        this.userPostalCode = "";
      } else if (field == "floor-input") {
        this.userFloor = "";
      } else if (field == "doorbell-input") {
        this.userDoorbell = "";
      }
    },

    submit: function () {
      let shouldSave = !this.isDeliveryInfoFormDisabled

      this.$store.dispatch("setDeliveryAddress", { address: this.userAddress, shouldSave: shouldSave });
      this.$store.dispatch("setDeliveryArea", { area: this.userArea, shouldSave: shouldSave });
      this.$store.dispatch("setDeliveryPostalCode", { postalCode: this.userPostalCode, shouldSave: shouldSave });
      this.$store.dispatch("setDeliveryFloor", { floor: this.userFloor, shouldSave: shouldSave });
      this.$store.dispatch("setDeliveryDoorbell", { doorbell: this.userDoorbell, shouldSave: shouldSave });

      this.closeForm();
    },

    closeForm: function () {
      this.$store.dispatch("deliveryInfoFormIsNotOpen");

      setTimeout(() => {
        this.synchronizeFormWithStoreData();
      }, 400);
    },

    synchronizeFormWithStoreData: function () {
      this.userAddress = this.$store.getters.getDeliveryAddress;
      this.userArea = this.$store.getters.getDeliveryArea;
      this.userPostalCode = this.$store.getters.getDeliveryPostalCode;
      this.userFloor = this.$store.getters.getDeliveryFloor;
      this.userDoorbell = this.$store.getters.getDeliveryDoorbell;
    },
  },
};
</script>

<style scoped>
#delivery-info-form {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 210;
  transition: all 0.3s ease-in-out;
}

.open {
  right: 0 !important;
}

#go-back-container {
  width: 100%;
  height: 8vh;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5%;
}

#back-to-cart-icon {
  font-size: calc(0.65rem + 2vw);
}

#delivery-info-title {
  font-weight: 600;
  font-size: calc(0.7rem + 1.5vw);
  color: #003340;
  margin-bottom: 0px;
  margin-left: 28vw;
}

@media screen and (max-width: 670px) {
  #delivery-info-title {
    margin-left: 25vw;
  }
}

@media screen and (max-width: 500px) {
  #delivery-info-title {
    margin-left: 22vw;
  }
}

@media screen and (max-width: 440px) {
  #delivery-info-title {
    margin-left: 20vw;
  }
}

@media screen and (max-width: 360px) {
  #delivery-info-title {
    margin-left: 18vw;
  }
}

@media screen and (max-width: 295px) {
  #delivery-info-title {
    margin-left: 16vw;
  }
}

@media screen and (max-width: 270px) {
  #delivery-info-title {
    margin-left: 14vw;
  }
}

#delivery-info-container {
  width: 100%;
  padding: 0 5%;
}

#address,
#area,
#doorbell,
#phone,
#submit-button-container {
  width: 100%;
}

#postal-code,
#floor {
  width: 45%;
}

#contact-us-form > h4,
#name > h4,
#phone > h4,
#address-label,
#area-label,
#postalc-code-label,
#floor-label,
#doorbell-label,
#phone {
  color: #333;
  font-weight: 600;
  font-size: 14px;
  align-self: flex-start;
  margin-left: 2px;
}

#address-input-container,
#area-input-container,
#postal-code-input-container,
#floor-input-container,
#doorbell-input-container,
#phone-input-container {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 6px;
  box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.2),
    inset 1px 1px 1px 0 rgba(0, 0, 0, 0.05);
  height: 35px;
  padding-left: 1px;
  background: #f9f9f9;
}

#area,
#postal-code-and-floor,
#doorbell,
#submit-button-container {
  margin-top: 30px;
}

#postal-code-and-floor {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

input {
  border: none;
  border-radius: 6px;
  width: 100%;
  padding: 0 10px 0 10px;
  background: #f9f9f9;
  -webkit-appearance: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f9f9f9 inset !important;
  box-shadow: 0 0 0 30px #f9f9f9 inset !important;
}

.clear-input {
  margin: 0 10px;
  color: #575757 !important;
}

input:focus {
  outline: none;
}

textarea {
  resize: none;
  outline: none;
  border: none;
  width: 100%;
  background: white;

  justify-self: center;
  align-self: center;
  min-height: 10vh;
  max-height: 17vh;
  box-shadow: inset 1px 1px 4px 0 rgba(0, 0, 0, 0.2),
    inset -1px -1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.2),
    inset 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 18px;
  padding-left: 13px;
  padding-top: 7px;
  margin-bottom: 5%;
}

#submit-button {
  width: 95%;
  height: 60%;
  min-height: 40px;
  border: none;
  background: linear-gradient(62deg, #198b4a, #04da6f);

  color: white;
  font-size: calc(0.6rem + 2vw);
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-left: 6px;
}

#submit-button:disabled {
  opacity: 0.55;
}
</style>