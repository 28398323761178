<template>
  <div id="choose-order-type-container">
    <h5 id="order-type-title">{{ labels.cart.typeOfOrder[language] }}</h5>
    <div>
      <div class="order-type-option">
        <input
          class="input"
          type="radio"
          value="DINE_IN"
          @change="onChangeOrderType"
          v-model="orderType"
        />
        <h6 @click="selectOrderType('DINE_IN')">
          {{ labels.cart.dineIn[language] }}
          <font-awesome-icon
            class="order-type-icon"
            :class="{ greenColor: orderType == 'DINE_IN' }"
            icon="utensils"
            @click="selectOrderType('DINE_IN')"
          />
        </h6>
      </div>
      <div class="order-type-option" style="margin-right: 16px;">
        <input
          class="input"
          type="radio"
          value="TAKE_AWAY"
          @change="onChangeOrderType"
          v-model="orderType"
        />
        <h6 @click="selectOrderType('TAKE_AWAY')">{{ labels.cart.takeAway[language] }}</h6>
        <font-awesome-icon
          class="order-type-icon"
          :class="{ greenColor: orderType == 'TAKE_AWAY' }"
          icon="shopping-bag"
          @click="selectOrderType('TAKE_AWAY')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChooseTypeOfOrder",
  props: ["tableType"],
  components: {
  },
  data() {
    return {
      orderType: 'DINE_IN'
    }
  },
  watch: {

  },
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    }

  },
  methods: {
    selectOrderType(orderType) {
      this.orderType = orderType;
      this.$emit("order-type-select", this.orderType)
    },
    onChangeOrderType() {
      this.$emit("order-type-select", this.orderType)
    }
  },
  mounted() {
  }
};
</script>

<style scoped>
#choose-order-type-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 1vh;
  border-bottom: 1px solid rgba(116, 135, 150, 0.2);
  padding: 7.5px 0 7.5px 0;
  color: black;
}

#order-type-title {
  font-weight: 600;
  font-size: 15px;
}

.order-type-option {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.order-type-icon {
  margin-left: 6px;
  margin-top: 3px;
  color: rgb(95, 95, 95);
}

.greenColor {
  color: #198b4a !important;
}

.order-type-option > h6 {
  font-size: 14px;
}

.input {
  accent-color: #198b4a;
  margin-bottom: 6px;
  margin-right: 5px;
}
</style>