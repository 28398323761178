<template>
  <div id="selected-card-container">
    <h4 @click="toggleDropdown" id="selected-card">
      {{ cardLabel(selectedCard) }}
      <font-awesome-icon id="card-select-icon" :icon="dropdownIcon"></font-awesome-icon>
    </h4>
    <div v-click-outside="closeCardDropdown" v-if="cardDropdownOpen" id="cards-dropdown">
      <h4
        v-for="card in availableCards"
        :key="card.id"
        :class="{ selected: card.selected }"
        @click="selectCard(card.id, card.label)"
        class="dropdownItem"
      >
        {{ cardLabel(card) }}
        <font-awesome-icon
          v-if="!card.id == 0"
          id="delete-card-icon"
          icon="trash-alt"
          @click="deleteCard(card)"
          :style="{ color: '#f30 ' }"
        />
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardDropdown",
  computed: {
    dropdownIcon() {
      return this.cardDropdownOpen ? "chevron-up" : "chevron-down";
    },

    availableCards() {
      return this.$store.getters.getAvailableCards;
    },

    selectedCard() {
      return this.$store.getters.getSelectedCard;
    },

    selectedCardLabel() {
      if (this.selectedCard.id == 0) {
        return this.labels.payment.newCard[this.language];
      } else {
        return card.label;
      }
    },

    language() {
      return this.$store.getters.getLanguage;
    },
  },
  data() {
    return {
      cardDropdownOpen: false,
    };
  },
  methods: {
    toggleDropdown: function () {
      this.cardDropdownOpen = !this.cardDropdownOpen;
    },

    closeCardDropdown: function () {
      this.cardDropdownOpen = false;
    },

    selectCard: function (id) {
      this.$store.dispatch("selectCard", id);
      this.closeCardDropdown();
    },

    cardLabel: function (card) {
      if (card.id == 0) {
        return this.labels.payment.newCard[this.language];
      } else {
        return card.label;
      }
    },

    deleteCard: function (card) {
      this.$router.push({ name: "cart-with-delete-card" });
      this.$store.dispatch("openDeleteCreditCardModal")
      this.$store.dispatch("setCreditCardToDelete", card)
    }
  },
};
</script>

<style scoped>
#selected-card-container {
  position: relative;
  display: flex;
  cursor: pointer;
}

#selected-card {
  color: #333;
  font-size: 12px;
  margin-top: 2px;
}

#card-select-icon {
  color: gray;
  margin-left: 7px;
  font-size: 12px;
  margin-top: 5px;
  cursor: pointer;
}

#cards-dropdown {
  position: absolute;
  bottom: 35px;
  right: 0;
  min-width: 120px;
  background: white;
  border-radius: 12px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.16), 2px 2px 8px rgba(0, 0, 0, 0.23);
}

.dropdownItem {
  display: flex;
  justify-content: space-between;
  padding: 11px 11px 11px 11px;
  min-width: 10vw;
  border-bottom: 1px solid rgba(56, 117, 77, 0.4);
  cursor: pointer;
  font-size: 12px;
  color: gray;
  margin: 0;
}

.dropdownItem:hover {
  background: #efefef;
}

.dropdownItem:last-child {
  border: none;
}

.selected {
  color: #198b4a;
  font-weight: bold;
}

#delete-card-icon {
  font-size: 14px;
  cursor: pointer;
}
</style>