<template>
  <div id="delivery-navbar">
    <font-awesome-icon
      @click="goToMenu"
      id="delivery-back-icon"
      icon="chevron-left"
      :style="{ color: '#198b4a ' }"
    />
    <p id="delivery-order-title">{{ labels.delivery.order[language] }}</p>
    <a v-if="!isApplication" id="link-to-landing" href="#">
      <img id="delivery-navbar-logo" src="../assets/Full_logo.svg" alt />
    </a>
  </div>
</template>

<script>
export default {
  props: ["isApplication"],
  computed: {
    tableId() {
      return this.$store.getters.getTableId;
    },

    language() {
      return this.$store.getters.getLanguage;
    },
  },
  methods: {
    goToMenu: function () {
      this.$router.push({ name: "menu", params: { tableId: this.tableId } });
    },
  },
};
</script>

<style scoped>
#delivery-navbar {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 53px;
  width: 100%;
  padding: 0 2.5% 0 5%;
  background: white;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  z-index: 206;
}

#delivery-back-icon {
  font-size: calc(0.65rem + 2vw);
  z-index: 2;
}

#delivery-order-title {
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  margin: 0;
  padding: 0;
  font-weight: 600;
}

#link-to-landing {
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
}

#delivery-navbar-logo {
  background: white;
  height: 55%;
  max-height: 31px;
  cursor: pointer;
}
</style>