<template>
  <div id="phone-input-container">
    <div id="header-and-input-row-container">
      <h5 id="phone-input-title">{{labels.cart.phoneCut[language]}}</h5>
      <vue-tel-input
        id="cart-phone-input"
        :class="{ smallerWidth: failedToFillPhone }"
        :defaultCountry="myCountry"
        placeholder
        :validCharactersOnly="true"
        :enabledCountryCode="true"
        @country-changed="countryChanged"
        @input="onPhoneChange"
        v-model="phone"
      ></vue-tel-input>
    </div>
    <h6
      v-if="failedToFillPhone"
      id="error-message"
    >*{{labels.cart.fillCorrectPhoneNumber[language]}}</h6>
    <h6
      v-if="isSmsSentToUserAtOrderExecution"
      id="info-message"
    >{{ isServiceWithDelayedResponse ? labels.cart.informViaSmsRegardingRequest[language] : labels.cart.informViaSmsRegardingOrder[language]}} {{ (tableType=='QR_SELF_SERVICE' || tableType=='SELF_SERVICE_POS') && deliveryType!='RENTALS' ? labels.cart.forPickup[language] : ''}}</h6>
  </div>
</template>

<script>
export default {
  name: "CartPhoneInput",
  props: ["failedToFillPhone", "isSmsSentToUserAtOrderExecution", "isServiceWithDelayedResponse", "tableType", "deliveryType"],
  components: {
  },
  data() {
    return {
      phone: null,
      dialCode: null,
      myCountry: "GR"
    }
  },
  watch: {

  },
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    }

  },
  methods: {
    onPhoneChange() {
      this.$emit("phone-input-change", this.phone, this.dialCode);
    },

    countryChanged(newCountry) {
      this.dialCode = newCountry.dialCode;
      this.$emit("phone-input-change", this.phone, this.dialCode);
    }
  },
  mounted() {
  }
};
</script>

<style scoped>
#phone-input-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 1vh;
  border-bottom: 1px solid rgba(116, 135, 150, 0.2);
  padding: 7.5px 0 7.5px 0;
  color: black;
}

#header-and-input-row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

#phone-input-title {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 0px;
  margin-right: 6px;
}

#cart-phone-input {
  background: #f7f7f7;
  margin-left: 4px;
  width: 85%;
  height: 4vh;
  min-height: 25px;
  outline: none;
  border: none;
  border-bottom: 1.5px solid #198b4a;
  -webkit-appearance: none !important;
}

#error-message {
  margin-bottom: 2px;
  margin-top: 2px;
  margin-bottom: 5px;
  padding-right: 4px;
  font-size: 13px;
  font-weight: 800;
  color: #000ecc;
}

#info-message {
  margin-bottom: 2px;
  font-size: 13px;
  font-weight: 600;
  color: #003340;
  width: 85%;
}

.smallerWidth {
  width: 79% !important;
}
</style>