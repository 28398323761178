<template>
  <div v-if="table" id="charging-room-container">
    <div id="room-label">
      <h6 id="charge-to-text">{{ labels.table.chargingRoom[this.language] }}:</h6>
      <v-select
        @open="onOpenDropdown"
        @input="onChangeRoom"
        v-model="selectedRoom"
        id="room-select-dropdown"
        :appendToBody="true"
        :placeholder="domSelectedRoom"
        label="tableNumber"
        :options="chargingRooms"
      ></v-select>
      <!-- <select v-if="chargingRooms" required v-model="domSelectedRoom">
          <option selected disabled>{{ domSelectedRoom }}</option>
          <option
            v-for="chargingRoom in chargingRooms"
            :key="chargingRoom.id"
            :value="chargingRoom"
          >{{ chargingRoom.tableNumber }}</option>
      </select>-->
    </div>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
export default {
  name: "ChargingRoomName",
  components: {},
  props: ["table"],
  data() {
    return {
      selectedRoom: null,
    };
  },
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    },

    chargingRooms() {
      return this.$store.getters.getAvailableChargingRooms;
    },

    domSelectedRoom: {
      get() {
        return this.labels.table.chooseYourRoom[this.language];
      },

      set(table) {
        this.$emit("charging-room-select", table);
        this.$store.dispatch("setSelectedChargingRoom", table);
      },
    },
  },
  methods: {
    onChangeRoom() {
      this.$emit("charging-room-select", this.selectedRoom);
      this.$store.dispatch("setSelectedChargingRoom", this.selectedRoom);
      setTimeout(() => {
        if (document.activeElement && document.activeElement.classList.contains("vs__search")) {
          try {
            document.activeElement.blur();
          } catch (error) {
            console.log("Could not hide keyboard", error);
          }
        }
      }, 130);
    },
    onOpenDropdown() {
      setTimeout(() => {
        window.scrollBy(0, 180);
      }, 150);

    }
  }
};
</script>

<style>
/* global styles */
.vs__dropdown-menu {
  color: #198b4a;
  background: #f7f7f7;
  font-weight: bold;
  font-size: 16px;
  max-height: 240px;
  font-family: "Inter", sans-serif;
}

.vs__dropdown-menu > li {
  margin-top: 8px;
}
</style>

<style scoped>
#charging-room-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 1vh;
  border-bottom: 1px solid rgba(116, 135, 150, 0.2);
  padding: 7.5px 0 7.5px 0;
  color: black;
}

#room-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
#charge-to-text {
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  margin-bottom: 0px;
}

#room-label > h6 > span {
  cursor: pointer;
  color: #198b4a;
  /* color: #ed5005;  */
  font-weight: bold;
  margin-left: 5px;
  font-size: 14px;
}

select {
  display: flex;
  align-self: center;
  justify-self: center;
  -webkit-appearance: auto !important;
  box-shadow: inset 0px 0px 2px rgba(116, 135, 150, 0.2),
    inset 1px 1px 2px rgba(116, 135, 150, 0.36);
  border-radius: 6px;
  margin-bottom: 0.5rem;
  margin-left: 4px;
  padding: 2px;
  color: #198b4a !important;
  font-weight: bold;
  background: #f7f7f7;
  max-width: 230px;
}

#room-select-dropdown {
  margin-left: 6px;
  flex: 0.75;
  max-width: 250px;
}

::v-deep .vs__selected-options {
  color: #198b4a;
  font-weight: bold;
}

::v-deep .vs__selected-options input {
  font-size: 14px;
}

::v-deep .vs__selected {
  color: #198b4a;
  font-weight: bold;
  font-size: 14px;
}

::v-deep .vs__dropdown-toggle {
  border-radius: 8px;
  background: #f7f7f7;
  box-shadow: inset 1px 1px 2px rgba(116, 135, 150, 0.36);
}

::v-deep #vs1__combobox {
  height: 32px;
}

@media screen and (max-width: 800px) {
  select {
    font-size: 13.2px;
  }

  #charge-to-text {
    font-size: 13.7px;
  }
}

@media screen and (max-width: 365px) {
  #room-select-dropdown {
    flex: 0.8;
  }
}

@media screen and (max-width: 358px) {
  select {
    font-size: 12.5px;
  }

  #charge-to-text {
    font-size: 13px;
  }
}

@media screen and (max-width: 345px) {
  #room-select-dropdown {
    flex: 0.9;
  }

  select {
    font-size: 12px;
  }

  #charge-to-text {
    font-size: 12.5px;
  }
}

@media screen and (max-width: 333px) {
  select {
    max-width: 190px;
  }
}

@media screen and (max-width: 325px) {
  #room-select-dropdown {
    flex: 0.98;
  }

  select {
    max-width: 177px;
  }
}

@media screen and (max-width: 308px) {
  select {
    max-width: 165px;
  }
}

@media screen and (max-width: 300px) {
  ::v-deep .vs__selected-options input {
    font-size: 12px;
  }

  ::v-deep .vs__selected {
    font-size: 12px;
  }

  ::v-deep #vs1__combobox {
    height: 28px;
  }
}

@media screen and (max-width: 293px) {
  select {
    max-width: 155px;
  }
}

@media screen and (max-width: 284px) {
  select {
    max-width: 150px;
  }
}

@media screen and (max-width: 278px) {
  select {
    max-width: 142px;
  }
}

@media screen and (max-width: 267px) {
  select {
    max-width: 135px;
  }
}

@media screen and (max-width: 258px) {
  select {
    max-width: 130px;
  }
}

option {
  color: #198b4a !important;
  font-weight: 600;
}
</style>